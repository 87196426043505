<template>
  <div class="swiper" :class="uniqueClass" v-bind="$attrs">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper margin-auto">
      <!-- Slides -->
      <slot name="default"></slot>
    </div>
    <!-- If we need pagination -->
    <div
      v-if="pagination"
      class="swiper-pagination ltr"
      :class="paginationComputedClass"
      :style="`
        --swiper-pagination-bullet-size: var(--font-s);
        --swiper-pagination-bullet-horizontal-gap: var(--margin-m);
        --swiper-pagination-bullet-inactive-color: var(--c-gray-4);
        --swiper-pagination-bullet-inactive-opacity: 1;
        --swiper-pagination-color: var(--c-alt);
      `"></div>

    <!-- If we need navigation buttons -->
    <slot v-if="!navigationOutside" name="navigation-prev">
      <div
        :class="navigationContainerClass"
        class="swiper-button swiper-button-prev border-radius-round"></div>
    </slot>
    <slot v-if="!navigationOutside" name="navigation-next">
      <div
        :class="navigationContainerClass"
        class="swiper-button swiper-button-next border-radius-round"></div>
    </slot>
  </div>
  <slot v-if="navigationOutside" name="navigation-prev" v-bind="{swiperInstance}">
    <div
      :class="navigationContainerClass"
      @click="swiperInstance.slideNext()"
      class="swiper-button swiper-button-prev border-radius-round"></div>
  </slot>
  <slot v-if="navigationOutside" name="navigation-next" @click="next()" v-bind="{swiperInstance}">
    <div
      :class="navigationContainerClass"
      @click="swiperInstance.slidePrev()"
      class="swiper-button swiper-button-next border-radius-round"></div>
  </slot>
</template>

<script>
export default {
  props: {
    navigationOutside: {
      type: Boolean,
      default: false,
    },
    navigationThemeType: {
      type: String,
      default: "default", // "default","minimal"
    },
    navigationBgTheme: {
      type: String,
      default: "gray-2",
    },
    navigationTheme: {
      type: String,
      default: "alt",
    },
    paginationBgTheme: {
      type: String,
      default: "",
    },
    paginationTheme: {
      type: String,
      default: "bg",
    },
    paginationClass: {
      type: String,
      default: "",
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    config: {},
  },
  data: function () {
    return {
      isSliding: false,
      Swiper: false,
      swiperInstance: false,
      uniqueClass: "slider-" + new Date().getTime() + "-" + utilities.getUniqueNumber(),
    };
  },
  computed: {
    navigationContainerClass() {
      const res = [];
      res.push(`c-${this.navigationTheme}`);

      res.push(`swiper-button--${this.navigationThemeType}`);

      if (this.navigationThemeType !== "minimal") {
        res.push(`bg-${this.navigationBgTheme}`);
      }

      return res;
    },
    // notice: this is not in use
    navigationIconClass() {
      const res = [];
      res.push(`c-${this.navigationTheme}`);
      res.push(`navigation--${this.navigationThemeType}`);

      if (this.navigationThemeType !== "minimal") {
        res.push(`bg-${this.navigationBgTheme}`);
      }

      return res;
    },
    paginationComputedClass() {
      const res = [this.paginationClass];
      res.push(`c-${this.paginationTheme}`);
      res.push(`bg-${this.paginationBgTheme}`);
      res.push(`swiper-button--${this.navigationThemeType}`);
      return res;
    },
  },
  created() {
    const SwiperCss = require("swiper/swiper-bundle.css");
  },
  async mounted() {
    await this.loadSwiper();
    // core version + navigation, pagination modules:
    // import Swiper bundle with all modules installed

    await utilities.inTwoTicks();
    await utilities.wait(200);

    this.initSlider();
    await utilities.wait(200);
  },
  methods: {
    reset() {
      try {
        this.initSlider();
      } catch (e) {
        // console.log('reset error');
        // console.log(e);
      }
    },
    async loadSwiper() {
      this.Swiper = await require("swiper/swiper-bundle.js");
      return true;
    },
    async initSlider() {
      if (this.swiperInstance) {
        this.swiperInstance.destroy();
        await utilities.wait(25);
      }

      // todo: basic settings without complex config object - make some props
      this.swiperInstance = new this.Swiper(`.${this.uniqueClass}`, {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 20,
        observeParents: true,
        parallax: true,
        // Navigation arrows
        navigation: {
          nextEl: `.${this.uniqueClass} .swiper-button-next`,
          prevEl: `.${this.uniqueClass} .swiper-button-prev`,
        },
        slideChangeTransitionEnd: () => {
          this.isSliding = false;
        },
        ...this.config,
      });
    },
    next() {
      console.log('hit next');
      try {
        if (this.swiperInstance.animation) {
          return false;
        }
        let targetIndex = this.swiperInstance.activeIndex + 1;
        let numSlides = this.swiperInstance.slides.length;
        if (targetIndex === numSlides) {
          targetIndex = 0;
        }

        this.swiperInstance.slideToLoop(targetIndex);
      } catch (e) {
        //  console.log(e);
        //  console.log('err in next',this.swiperInstance);
      }
    },
    prev() {
      try {
        if (this.swiperInstance.animation) {
          return false;
        }
        let targetIndex = this.swiperInstance.activeIndex - 1;
        let numSlides = this.swiperInstance.slides.length;
        if (targetIndex < 0) {
          targetIndex = numSlides - 1;
        }

        this.swiperInstance.slideToLoop(targetIndex);
        return true;
      } catch (e) {}
    },
    getApi() {
      return this.swiperInstance;
    },
  },
};
</script>

<style scoped lang="scss">
.swiper-button {
  --swiper-navigation-size: calc(var(--base-margin) * 30);
  height: calc(var(--base-margin) * 50);
  width: calc(var(--base-margin) * 50);

  &--minimal {
    width: fit-content;
    background: transparent !important;
    --swiper-navigation-sides-offset: 0;
  }
}
</style>
